import { Controller, useFormContext } from "react-hook-form";

import Checkbox from "./sbb/Checkbox";

declare interface AppCheckboxProps {
  disabled?: boolean;
  label: string;
  name: string;
}

export default function AppCheckbox({ name, ...props }: AppCheckboxProps) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name: n, value, ...fieldProps } }) => (
        <Checkbox checked={value} value={n} {...fieldProps} {...props} />
      )}
    />
  );
}
