export declare interface TableProps {
  children: React.ReactNode;
  className?: string;
}

export declare interface HeaderCellProps {
  children?: React.ReactNode;
  className?: string;
}

export declare interface CellProps {
  children?: React.ReactNode;
  className?: string;
  rowSpan?: number;
}

type RowProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLTableRowElement>;

function Table({ children, className = "" }: TableProps) {
  return <table className={className}>{children}</table>;
}

function Cell({ children, className = "", rowSpan, ...props }: CellProps) {
  return (
    <td
      className={`border-b border-graphite px-4 py-2 group-hover:border-b-red125 group-hover:bg-milk ${className}`}
      rowSpan={rowSpan}
      {...props}
    >
      {children}
    </td>
  );
}

function HeaderCell({ children, className = "" }: HeaderCellProps) {
  return (
    <th
      className={`border-b border-graphite px-4 py-2 text-left font-bold ${className}`}
    >
      {children}
    </th>
  );
}

function Row({ children, className = "", ...props }: RowProps) {
  return (
    <tr className={`group ${className}`} {...props}>
      {children}
    </tr>
  );
}

Table.Cell = Cell;
Table.HeaderCell = HeaderCell;
Table.Row = Row;

export default Table;
